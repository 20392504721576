import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ContextState from "./Configs/Context/ContextState";
import Layout from "./Components/Layout";
import Home from "./Pages/Home";
import ROUTES from "./Configs/Routes";
import PageNotFound from "./Pages/PageNotFound";
import AboutUs from "./Pages/AboutUs";
import Network from "./Pages/Network";
import Ecosystem from "./Pages/Ecosystem";
import News from "./Pages/News";
import NewsDetails from "./Pages/News/NewsDetails";
import SwapLayout from "./Components/Swap/SwapLayout";
import Swap from "./Pages/Swap/Swap";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ContextState>
          <Routes>
            <Route element={<Layout />}>
              <Route path={ROUTES.HOME} element={<Home />} />
              <Route path={ROUTES.ABOUT} element={<AboutUs />} />
              <Route path={ROUTES.NETWORK} element={<Network />} />
              <Route path={ROUTES.ECOSYSTEM} element={<Ecosystem />} />
              <Route path={ROUTES.BLOG} element={<News />} />
              <Route path={ROUTES.BLOG_DETAIL} element={<NewsDetails />} />
              <Route path={'/*'} element={<PageNotFound />} />

              <Route element={<SwapLayout />}>
                <Route path={ROUTES.SWAP} element={<Swap />} />
              </Route>
            </Route>
          </Routes>

          <ToastContainer />
        </ContextState>
      </BrowserRouter>
    </div>
  );
}

export default App;
