import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import bnb from '../../Assets/Images/icons/bnb.png';
import pancake from '../../Assets/Images/icons/pancake.png';

const SelectToken = ({ setTokenModal, selectedToken, setManageTokenModal }) => {
  const commonToken = [{ icon: bnb, label: 'BNB' }, { icon: bnb, label: 'USDT' }, { icon: pancake, label: 'CAKE' }, { icon: bnb, label: 'BTCB' }];
  const tokenList = [
    { title: 'BNB', subTitle: 'Binance Chain Native Token' },
    { title: '8PAY v2', subTitle: '8 Pay Network v2' },
    { title: 'ACE', subTitle: 'ACEtoken' },
    { title: 'ACH', subTitle: 'Alchemy Token' },
    { title: 'ADA', subTitle: 'Cardano Token' },
    { title: 'ADX', subTitle: 'AdEx Network' },
    { title: 'agEUR', subTitle: 'agEUR' },
  ]

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Select a Token</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control type='text' className='tokenSearch' placeholder='Search Name Or Paste Address' />
        <p className='my-3'>Common Token</p>

        <div className='commonTokenDiv mb-4'>
          {commonToken?.map((item, id) => {
            return (
              <Button key={id} className={selectedToken === item.label ? 'selected' : ''} disabled={selectedToken === item.label}><img src={item.icon} alt={item.label} /> {item.label}</Button>
            )
          })}
        </div>

        <div className='tokenListDiv mb-4'>
          {tokenList?.map((item, id) => {
            return (
              <div key={id} className={`tokenLi mb-3 ${selectedToken === item.title ? 'selected' : ''}`}>
                <img src={bnb} alt={item.label} />
                <div>
                  <h6 className='mb-0'>{item.title}</h6>
                  <p className='mb-0'>{item.subTitle}</p>
                </div>
                <span className='ms-auto'><i className="fa-solid fa-arrow-right-long" /></span>
              </div>
            )
          })}
        </div>
        <Button className='manageTokenBtn' onClick={() => { setTokenModal(false); setManageTokenModal(true); }}>Manage Token</Button>
      </Modal.Body>
    </>
  )
}

export default SelectToken