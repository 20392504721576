import React, { useEffect } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import logo from '../Assets/Images/logo.png';
import ROUTES from '../Configs/Routes';
import { Link, NavLink, useLocation } from 'react-router-dom';
import arrow from '../Assets/Images/icons/arrow.png';
// import WOW from 'wowjs';

const Header = () => {
  const location = useLocation();

  const navMenu = [
    {
      name: 'Home',
      path: ROUTES.HOME,
    },
    {
      name: 'About Us',
      path: ROUTES.ABOUT,
    },
    // {
    //   name: 'How To Buy',
    //   path: ROUTES.HOW_BUY,
    // },
    // {
    //   name: 'Earn Oxodise',
    //   path: ROUTES.EARN_ABC,
    // },
    {
      name: 'Network',
      path: ROUTES.NETWORK,
    },
    {
      name: 'Ecosystem',
      path: ROUTES.ECOSYSTEM,
    },
    {
      name: 'Blog',
      path: ROUTES.BLOG,
    },
  ]

  // useEffect(() => {
  //   const wow = new WOW.WOW({
  //     boxClass: 'wow', // default
  //     animateClass: 'animate__animated', // default
  //     offset: 0, // default
  //     mobile: false, // default
  //     live: true, // default
  //   });
  //   wow.init();
  // }, [])

  return (
    <>
      <Navbar expand='lg' className='mainNavbar py-3 py-lg-4 wow animate__animated animate__fadeInDown' id='mainNavbar'>
        <Container>
          <Navbar.Brand className='py-0'>
            <Link to={ROUTES.HOME}>
              <img src={logo} alt='logo' className='headerLogo' />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='navbarScroll' />
          <Navbar.Collapse id='navbarScroll'>
            <Nav className='mx-auto	align-items-center'>
              {navMenu.map((item, id) => {
                return (
                  <Nav.Link key={id} to={item.path} as={NavLink} className={location.pathname === item.path ? 'active' : ''}>{item.name}</Nav.Link>
                )
              })}
            </Nav>
            <Link to={ROUTES.HOME} className='themeBtn mx-auto mx-lg-2 mt-2 mt-lg-0'>Buy Oxodise <img src={arrow} alt='' /></Link>
            <Link to={ROUTES.HOME} className='themeBtn headerBtn mx-auto mx-lg-2 mt-2 mt-lg-0'>Buy Node <img src={arrow} alt='' /></Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default Header