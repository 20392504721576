import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../Assets/Css/about.css';
import aboutImg from '../../Assets/Images/aboutImg.png';
import logo from '../../Assets/Images/logo.png';
import mission from '../../Assets/Images/mission.png';

const AboutUs = () => {
  return (
    <>
      <div className='aboutBannerSec'>
        <Container>
          <h2 className='bannerTitle text-center'>About Us</h2>
        </Container>
      </div>

      <div className='aboutSec pt-150'>
        <Container>
          <Row className='align-items-center justify-content-center g-4'>
            <Col xs={10} sm={9} md={8} lg={7} xl={6}>
              <div className='aboutImg text-center'>
                <img src={aboutImg} alt='about-us' />
              </div>
            </Col>
            <Col lg={10} xl={6} className='aboutTxt text-center text-xl-start'>
              <p className='mb-4'>Oxodise is a decentralized ecosystem comprising blockchain-based decentralized applications (DApps), operating through a network of distributed nodes to ensure resilience against network failure and facilitate peer-to-peer transactions. Fueled by the Oxodise coin, a utility token providing access to various services and apps, Oxodise aims to disrupt traditional infrastructures with robust, secure, and efficient blockchain solutions.</p>

              <p className='mb-4'>The primary objective of the Oxodise project is to pioneer next-gen blockchain-based solutions that reshape traditional industries. Spanning gaming, trading, news, payments, and supply chain management, the ecosystem drives innovation through widespread blockchain adoption. Leveraging the secure Binance Smart Chain (BSC), the Oxodise ecosystem ensures fast and cost-effective transactions for all its decentralized apps (DApps).</p>

              <p>The Oxodise coin serves as both a utility token and digital asset, powering services and DApps within the Oxodise Ecosystem. With multiple use cases, it enables access to innovative DApps like Oxodise Pay, Oxodise Verse, Oxodise Chain, Oxodise Explorer, Oxodise Change, Oxodise Connect, Oxodise Press, and Oxodise Vault. The Oxodise Ecosystem is actively developing and will soon launch a diverse range of decentralized platforms and apps, all fueled by the Oxodise Coin, across various sectors.</p>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='aboutSec2 pt-150'>
        <Container>
          <Row className='justify-content-center gy-5'>
            <Col lg={9} xl={6} className='aboutDiv text-center text-md-start'>
              <div>
                <img src={logo} alt='' className='headerLogo d-block mx-auto mx-md-0' />
                <h2 className='mainTitle2 gradientClr my-3 my-md-4'>Why Oxodise?</h2>
                <p>Oxodise stands out due to its expansive and inclusive ecosystem. Unlike focusing on a single niche, we provide solutions for a variety of sectors all under one roof. Our ecosystem encompasses everything from gaming, trading, news, exploration, payments to supply chain management, offering diverse use cases and utility.</p>
              </div>
            </Col>
            <Col lg={9} xl={6} className='aboutDiv text-center text-md-start'>
              <div>
                <Row className='justify-content-center align-items-center'>
                  <Col xs={7} sm={5}>
                    <img src={mission} alt='' className='missionImg' />
                  </Col>
                  <Col md={7}>
                    <h2 className='mainTitle2 gradientClr mt-3 mt-md-0 mb-3 mb-md-4'>Mission & Vision</h2>
                    <p>At Oxodise, we are dedicated to empowering individuals and businesses with a comprehensive, secure, and user-friendly blockchain ecosystem. Our mission is to foster the widespread adoption and comprehension of blockchain technology across various sectors, including gaming, finance, news, supply chain, and beyond.</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default AboutUs