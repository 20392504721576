import React, { useContext, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { FiHelpCircle } from 'react-icons/fi';
import MainContext from '../../Configs/Context/MainContext';

const SlippageSetting = ({ setSlippageModal, setExpertModal }) => {
  const context = useContext(MainContext);

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <p className='text-black fw-bold mb-0' style={{ fontSize: '12px' }}>SWAPS & LIQUIDITY</p>

        <div className='mb-3'>
          <p>Default Transaction Speed (GWEI) <FiHelpCircle /></p>
          <div className='transSpeed d-flex gap-2'>
            <Button className='flex-fill active'>Default</Button>
            <Button className='flex-fill'>Standard (1)</Button>
            <Button className='flex-fill'>Fast (4)</Button>
            <Button className='flex-fill'>Instant (5)</Button>
          </div>
        </div>
        <div className='mb-3'>
          <p>Slippage Tolerance <FiHelpCircle /></p>
          <div className='transSpeed d-flex gap-2'>
            <Button className='active'>0.1%</Button>
            <Button>0.5%</Button>
            <Button>1.0%</Button>
            <Button>1.00%</Button>
          </div>
        </div>

        <div className='mb-2'>
          <div className='slippageSwitch mb-2'>
            <p className='mb-0'>Tx deadline (mins) <FiHelpCircle /></p>
            <div className='slippageTag'>20</div>
          </div>
          <div className='slippageSwitch mb-2'>
            <p className='mb-0'>Expert Mode <FiHelpCircle /></p>
            <Form.Check type="switch" id="custom-switch1" name="switch1" checked={context.expertMode} onChange={() => { if (!context.expertMode) { setSlippageModal(false); setExpertModal(true); } else { context.setExpertMode(false) } }} />
          </div>
          <div className='slippageSwitch mb-2'>
            <p className='mb-0'>Flippy sounds <FiHelpCircle /></p>
            <Form.Check type="switch" id="custom-switch2" name="switch2" />
          </div>
          <div className='slippageSwitch mb-2'>
            <p className='mb-0'>Fast routing (BETA) <FiHelpCircle /></p>
            <Form.Check type="switch" id="custom-switch3" name="switch3" />
          </div>
        </div>
        <Button className='manageTokenBtn fw-bold'>Customize Routing</Button>

      </Modal.Body>
    </>
  )
}

export default SlippageSetting