const HOME = '/';
const ABOUT = '/about-us';
const HOW_BUY = '/how-to-buy';
const EARN_ABC = '/earn-Oxodise';
const NETWORK = '/network';
const ECOSYSTEM = '/ecosystem';
const BLOG = '/blogs';
const BLOG_DETAIL = '/blog-details';
const SWAP = '/swap';
const LIQUIDITY = '/liquidity';
const FARMING = '/farming';
const STALKING = '/stalking';

const ROUTES = {
  HOME,
  ABOUT,
  HOW_BUY,
  EARN_ABC,
  NETWORK,
  ECOSYSTEM,
  BLOG,
  BLOG_DETAIL,
  SWAP,
  LIQUIDITY,
  FARMING,
  STALKING,
}

export default ROUTES;