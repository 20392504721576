import React, { useState } from 'react'
import MainContext from './MainContext'

export default function ContextState(props) {

  const [expertMode, setExpertMode] = useState(false);

  const value = {
    expertMode, setExpertMode,
  }

  return (
    <MainContext.Provider value={value}>
      {props.children}
    </MainContext.Provider>
  )
}