import React from 'react';
import '../../Assets/Css/network.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ROUTES from '../../Configs/Routes';
import NetworkTransData from './NetworkTransData';
import PartnerSlider from '../Home/PartnerSlider';
import NewsSlider from '../News/NewsSlider';
import arrow from '../../Assets/Images/icons/arrow.png';
import networkImg from '../../Assets/Images/networkImg.png';
import ecoBrief from '../../Assets/Images/ecoBrief.png';
import scalable from '../../Assets/Images/scalable.png';
import affordable from '../../Assets/Images/affordable.png';
import integration from '../../Assets/Images/integration.png';
import seamless from '../../Assets/Images/seamless.png';
import sustainable from '../../Assets/Images/sustainable.png';
import micropayment from '../../Assets/Images/micropayment.png';
import management from '../../Assets/Images/management.png';
import varification from '../../Assets/Images/varification.png';

const Network = () => {

  // window.Jupiter.init({
  //   // displayMode: "integrated",
  //   // integratedTargetId: "integrated-terminal",
  //   endpoint: "https://api.mainnet-beta.solana.com",
  // });

  return (
    <>
      {/* <div id="integrated-terminal"></div> */}
      <div className='networkBanner pt-5'>
        <Container>
          <Row className='justify-content-center align-items-center gy-5'>
            <Col lg={6} xxl={5} className='text-center text-lg-start'>
              <h2 className='mainTitle'>Oxodise Network</h2>
              <h3 className='mainTitle2 gradientClr mb-3'>L2 BLOCKCHAIN</h3>
              <p className='pb-4'>It boosts transaction speed and reduces costs by adding a scalable, efficient layer atop the primary blockchain.</p>
              <Link to={ROUTES.SWAP} className='themeBtn mx-auto mx-lg-0'>Explore <img src={arrow} alt='' /></Link>
            </Col>
            <Col xs={10} sm={10} md={8} lg={6} xxl={5} className='text-center'>
              <img src={networkImg} alt='network' />
            </Col>
          </Row>

          <div className='mt-5'>
            <NetworkTransData />
          </div>
        </Container>
      </div>

      <div className='partnerSec pt-150'>
        <Container>
          <h2 className='mainTitle text-center mb-4'>PARTNERS & SUPPORTERS</h2>

          <PartnerSlider />
        </Container>
      </div>

      <div className='newsSlider pt-5 mt-5'>
        <Container>
          <h2 className='mainTitle text-center mb-4'>Oxodise IN THE NEWS</h2>

          <NewsSlider />
        </Container>
      </div>

      <div className='ecoBriefSec pt-5 mt-5'>
        <Container>
          <Row className='justify-content-center align-items-center'>
            <Col xs={10} sm={10} md={9} lg={6} className='text-center'>
              <img src={ecoBrief} alt='ecoBrief' />
            </Col>
            <Col lg={6} xxl={5} className='text-center text-lg-start'>
              <h2 className='mainTitle mb-4'>A BRIEF ABOUT <span className='gradientClr'>Oxodise</span> ECOSYSTEM!</h2>
              <p>The Oxodise Ecosystem offers a comprehensive solutions driven by the Oxodise Coin. It introduces a diverse range of decentralized solutions and revolves around Oxodise Chain, Oxodise Pay, Oxodise Press, Oxodise Verse, Oxodise Change, Oxodise Connect, Oxodise Explorer and Oxodise Vault.</p>
            </Col>
          </Row>

          <Row className='justify-content-center justify-content-lg-start g-4 mt-2'>
            <Col md={6} lg={4}>
              <div className='ecoBriefDiv'>
                <img src={scalable} alt='scalable' />

                <h4 className='gradientClr'>SCALABILITY</h4>
                <p>Low block time, high TPS, and high throughput, millions of NFTs can be minted in seconds.</p>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className='ecoBriefDiv'>
                <img src={affordable} alt='scalable' />

                <h4 className='gradientClr'>AFFORDABILITY</h4>
                <p>Fast, low fee transactions, even for global games requiring thousands of transactions per minute.</p>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className='ecoBriefDiv'>
                <img src={integration} alt='scalable' />

                <h4 className='gradientClr'>EASY INTEGRATION</h4>
                <p>Easy, low-cost NFT integration into games and platforms without need for blockchain coding resources.</p>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className='ecoBriefDiv'>
                <img src={seamless} alt='scalable' />

                <h4 className='gradientClr'>SEAMLESS END-TO-END APPS</h4>
                <p>Complete, seamless, and robust end-to-end app layer, from wallet to marketplace.</p>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className='ecoBriefDiv'>
                <img src={sustainable} alt='scalable' />

                <h4 className='gradientClr'>SUSTAINABILITY</h4>
                <p>Built on Substrate architecture, which is recognized as the greenest proof-of-stake chain.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='netWorkUseSec pt-150'>
        <Container>
          <Row>
            <Col lg={10} xl={9} xxl={8} className='mx-auto text-center'>
              <h2 className='mainTitle mb-4'>Oxodise NETWORK USE CASES</h2>
              <p className='mb-4'>Exploring the transformative potential of Oxodise Network, these use cases showcase its capability to revolutionize industries by enhancing efficiency, security, and transparency across various applications.</p>
            </Col>
          </Row>

          <Row className='justify-content-center align-items-center my-5 g-4'>
            <Col xs={8} sm={7} md={6} lg={6} xxl={5} className='text-center'>
              <img src={micropayment} alt='ecoBrief' />
            </Col>
            <Col lg={6} xxl={5} className='text-center text-lg-start'>
              <h2 className='mainTitle2 mb-4'>MICROPAYMENTS</h2>
              <p>Oxodise Network technology revolutionizes the concept of micropayments, making it economically viable to process transactions of very small amounts with negligible fees. This is particularly beneficial for online content providers, subscription-based services, and tipping platforms, where traditional financial systems' transaction costs would otherwise be prohibitive. By enabling these small-value transactions, L2 Blockchain opens up new revenue streams and business models, allowing creators and service providers to monetize their offerings more effectively.</p>
            </Col>
          </Row>
          <Row className='justify-content-center align-items-center my-5 g-4'>
            <Col xs={8} sm={7} md={6} lg={6} xxl={5} className='text-center order-first order-lg-last'>
              <img src={management} alt='ecoBrief' />
            </Col>
            <Col lg={6} xxl={5} className='text-center text-lg-start'>
              <h2 className='mainTitle2 mb-4'>SUPPLY CHAIN MANAGEMENT</h2>
              <p>In supply chain management, Oxodise Network significantly enhances the visibility and integrity of the supply chain by providing a transparent, immutable ledger for tracking goods from origin to consumer. This capability ensures that all stakeholders can verify the authenticity of products, monitor their journey in real-time, and identify inefficiencies within the supply chain. By mitigating risks such as counterfeiting and fraud, L2 Blockchain fosters trust among participants and streamlines operations, leading to cost savings and improved customer satisfaction.</p>
            </Col>
          </Row>
          <Row className='justify-content-center align-items-center my-5 g-4'>
            <Col xs={8} sm={7} md={6} lg={6} xxl={5} className='text-center'>
              <img src={varification} alt='ecoBrief' />
            </Col>
            <Col lg={6} xxl={7} className='text-center text-lg-start'>
              <h2 className='mainTitle2 mb-4'>IDENTITY VERIFICATION AND MANAGEMENT</h2>
              <p className='pe-xxl-5 me-xxl-5'>Oxodise Network technology offers a decentralized approach to identity verification and management, enabling secure, efficient, and tamper-proof storage of personal identity information. This application allows individuals to control their personal data and share it selectively, enhancing privacy and security. It can streamline processes for online verification, access control, and fraud prevention across various sectors, including finance, healthcare, and government services. By leveraging L2 solutions, organizations can reduce reliance on centralized identity repositories, minimizing the risk of data breaches and identity theft.</p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Network