import React from 'react';
import { Link } from 'react-router-dom';
import ROUTES from './../../Configs/Routes';
import { Accordion, Col, Container, Form, Row } from 'react-bootstrap';
import audit from '../../Assets/Images/icons/audit.png';
import kyc from '../../Assets/Images/icons/kyc.png';
import whitepaper from '../../Assets/Images/icons/whitepaper.png';
import bannerImg from '../../Assets/Images/homeBannerImg.png';
import blockSAFU from '../../Assets/Images/blockSAFU.png';
import projectKYC from '../../Assets/Images/projectKYC.png';
import abcChainImg from '../../Assets/Images/abcChainImg.png';
import abcChangeImg from '../../Assets/Images/abcChangeImg.png';
import arrow from '../../Assets/Images/icons/arrow.png';
import trade from '../../Assets/Images/icons/trade.png';
import tokenomicsImg from '../../Assets/Images/tokenomicsImg.png';
import faqImg from '../../Assets/Images/faqImg.png';
import contactImg from '../../Assets/Images/contactImg.png';
import { socialMedia } from './../../Configs/constants';
import UsecaseSlider from './UsecaseSlider';
import PartnerSlider from './PartnerSlider';
import NewsSlider from '../News/NewsSlider';
import RoadmapSlider from './RoadmapSlider';
import NetworkTransData from '../Network/NetworkTransData';
import { IoDocumentTextOutline } from 'react-icons/io5';

const Home = () => {

  return (
    <>
      <div className='bannerSec'>
        <Container>
          <Row className='align-items-center justify-content-center'>
            <Col lg={6} className='py-5 text-center text-lg-start'>
              <div className='pe-xl-5'>
                <h2 className='bannerTitle pe-xl-5 me-xl-5'>A Brief About <span className='gradientClr'>Oxodise</span> Ecosystem!</h2>
                <p className='bannerP my-4'>Your Gateway to a Decentralized Future, Empowering innovation with our state-of-the-art blockchain technology</p>
              </div>
              <div className='d-flex flex-wrap flex-sm-nowrap justify-content-center justify-content-lg-start gap-3'>
                <Link to={ROUTES.HOME} className='themeBtn'><img src={audit} alt='' /> Audit</Link>
                <Link to={ROUTES.HOME} className='themeBtn'><img src={kyc} alt='' /> KYC</Link>
                <Link to={ROUTES.HOME} className='themeBtn'><img src={whitepaper} alt='' /> Whitepaper</Link>
              </div>
            </Col>

            <Col xs={10} sm={9} md={8} lg={6}>
              <div className='bannerImg'>
                <img src={bannerImg} alt='' />
              </div>
            </Col>
          </Row>
          <div className='socialList'>
            {socialMedia.map((item) => {
              return (
                <a href={item.link} target='_blank' rel='noreferrer' key={item.id}>
                  <img src={item.icon} alt='' />
                </a>
              )
            })}
          </div>
        </Container>
      </div>

      <div className='auditKycSec pt-150'>
        <Container>
          <Row className='justify-content-center g-4'>
            <Col md={10} lg={6} className='auditKycDiv'>
              <div>
                <img src={blockSAFU} alt='' />
                <h2 className='mainTitle'>AUDIT</h2>
                <p>BlockSAFU has audited and verified the legitimacy and robust security of the Oxodise Ecosystem and its smart contracts.</p>
              </div>
            </Col>
            <Col md={10} lg={6} className='auditKycDiv'>
              <div>
                <img src={projectKYC} alt='' />
                <h2 className='mainTitle'>KYC</h2>
                <p>BlockSAFU has confirmed the legitimacy of the Oxodise team through a comprehensive KYC verification process, ensuring verified project ownership and detecting no suspicious activities.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='abcChainSec pt-150'>
        <Container>
          <Row className='align-items-center justify-content-center'>
            <Col xs={10} sm={7} md={6} lg={5} xl={6} xxl={5}>
              <div className='abcChainImg text-center'>
                <img src={abcChainImg} alt='abcChainImg' />
              </div>
            </Col>
            <Col lg={7} xl={6} className='abcChainTxt py-5 text-center text-lg-start'>
              {/* <h2 className='mainTitle'>Oxodise NETWORK CHAIN (BNC)</h2> */}
              <h3 className='mainTitle2 gradientClr'>Oxodise BlockChain</h3>
              <p className='my-4'>The Oxodise Chain is a robust blockchain engineered to support diverse applications, leveraging advanced cryptographic security, innovative consensus mechanisms like Proof of Stake (PoS), and sophisticated smart contract capabilities for fast, reliable, and transparent transactions. Its scalability and decentralization empower developers and businesses to innovate securely and efficiently, ensuring trust and accountability in every transaction. With its cutting-edge technology, the Oxodise Chain paves the way for a decentralized future, where transparency and efficiency drive innovation across various industries.</p>
              <div className='d-flex flex-wrap justify-content-center justify-content-lg-start gap-3'>
                <Link to={ROUTES.HOME} className='themeBtn'>Learn More <img src={arrow} alt='' /></Link>
                <Link to={ROUTES.HOME} className='themeBtn'>Explore <img src={arrow} alt='' /></Link>
              </div>
            </Col>
          </Row>

          <div className='mt-xl-4'>
            <NetworkTransData />
          </div>
        </Container>
      </div>

      <div className='abcChangeSec pt-150'>
        <Container>
          <h2 className='mainTitle text-center'>OXODISE ECOSYSTEM</h2>
          <Row className='align-items-center justify-content-evenly text-center text-lg-start'>
            <Col lg={6} xxl={5} className='py-4 abcChangeDiv'>
              <h3 className='mainTitle2 gradientClr mb-4'>The Ecosystem is growing</h3>
              <p>The Oxodise Ecosystem is a comprehensive suite of tools and services designed to maximize the potential of the Oxodise Chain. From decentralized finance to enterprise solutions, our ecosystem offers a wide range of applications to cater to all your needs.</p>
            </Col>
            <Col xs={10} sm={9} md={8} lg={6} xxl={5} className='order-first order-lg-last'>
              <div>
                <img src={abcChangeImg} alt='' />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='useCaseSec pt-150'>
        <Container>
          <h2 className='mainTitle text-center mb-4'>USE CASES</h2>
          <div>
            <UsecaseSlider />
          </div>
        </Container>
      </div>

      <div className='exchangeSec pt-150'>
        <Container>
          <h2 className='mainTitle text-center'>TRADE ON EXCHANGE</h2>
          <p className='text-center my-4'>Become a Oxodise Coin investor by purchasing it on leading crypto exchange platforms. Unlock a world of financial possibilities and navigate the dynamic trading landscape seamlessly. Elevate your trading experience with intuitive platforms and explore the potential for financial growth in the exciting realm of exchange trading.</p>
          <Row className='gy-5 justify-content-center'>
            <Col sm={9} md={6} lg={4}>
              <div className='exchangeDiv'>
                <h3>XT EXCHANGE</h3>
                <div className='d-flex justify-content-between p-3'>
                  <div>
                    <p>Listing Date :</p>
                    <p>Listing Price :</p>
                    <p>Status :</p>
                  </div>
                  <div>
                    <p>20 May 2024</p>
                    <p>$0.1</p>
                    <p className='gradientClr fw-bold'>Live</p>
                  </div>
                </div>
                <Link to={ROUTES.HOME} className='themeBtn exchangeBtn mx-auto'>Trade Now <img src={trade} alt='' /></Link>
              </div>
            </Col>
            <Col sm={9} md={6} lg={4}>
              <div className='exchangeDiv'>
                <h3>LATOKEN EXCHANGE</h3>
                <div className='d-flex justify-content-between p-3'>
                  <h4 className='w-100 text-center my-4'>Announce Soon</h4>
                </div>
              </div>
            </Col>
            <Col sm={9} md={6} lg={4}>
              <div className='exchangeDiv'>
                <h3>MEXC GLOBAL EXCHANGE</h3>
                <div className='d-flex justify-content-between p-3'>
                  <h4 className='w-100 text-center my-4'>Announce Soon</h4>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='icoSec pt-150'>
        <Container>
          <h2 className='mainTitle text-center'>ICO ROUNDS COMPLETED</h2>

          <div className='d-flex flex-wrap justify-content-center gap-3 my-4 my-xxl-5'>
            <Link to={ROUTES.HOME} className='themeBtn themeBtn2'>Pre Sale</Link>
            <Link to={ROUTES.HOME} className='themeBtn'>IDO Sale</Link>
            <Link to={ROUTES.HOME} className='themeBtn themeBtn2'>IEO Sale</Link>
          </div>

          <Row className='g-4 justify-content-center'>
            <Col md={6} lg={4}>
              <div className='exchangeDiv'>
                <h3 className='d-flex justify-content-between'>PHASE 1 <span>COMPLETE</span></h3>
                <div className='icoDates d-flex justify-content-center gap-3 p-3 pb-2'>
                  <Link to={ROUTES.HOME} className='themeBtn'>Start Date <span>25 Jan, 2024</span></Link>
                  <Link to={ROUTES.HOME} className='themeBtn themeBtn2'>End Date <span>31 Jan, 2024</span></Link>
                </div>
                <div className='d-flex justify-content-between p-3'>
                  <div>
                    <p>Token :</p>
                    <p>Price :</p>
                    <p>Status :</p>
                  </div>
                  <div>
                    <p>35,000,000 Oxodise</p>
                    <p>$0.0400</p>
                    <p>Complete</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className='exchangeDiv'>
                <h3 className='d-flex justify-content-between'>PHASE 2 <span>COMPLETE</span></h3>
                <div className='icoDates d-flex justify-content-center gap-3 p-3 pb-2'>
                  <Link to={ROUTES.HOME} className='themeBtn'>Start Date <span>1 Feb, 2024</span></Link>
                  <Link to={ROUTES.HOME} className='themeBtn themeBtn2'>End Date <span>5 Feb, 2024</span></Link>
                </div>
                <div className='d-flex justify-content-between p-3'>
                  <div>
                    <p>Token :</p>
                    <p>Price :</p>
                    <p>Status :</p>
                  </div>
                  <div>
                    <p>35,000,000 Oxodise</p>
                    <p>$0.0500</p>
                    <p>Complete</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className='exchangeDiv'>
                <h3 className='d-flex justify-content-between'>PHASE 3 <span>COMPLETE</span></h3>
                <div className='icoDates d-flex justify-content-center gap-3 p-3 pb-2'>
                  <Link to={ROUTES.HOME} className='themeBtn'>Start Date <span>6 Feb, 2024</span></Link>
                  <Link to={ROUTES.HOME} className='themeBtn themeBtn2'>End Date <span>10 Feb, 2024</span></Link>
                </div>
                <div className='d-flex justify-content-between p-3'>
                  <div>
                    <p>Token :</p>
                    <p>Price :</p>
                    <p>Status :</p>
                  </div>
                  <div>
                    <p>35,000,000 Oxodise</p>
                    <p>$0.0600</p>
                    <p>Complete</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='partnerSec pt-150'>
        <Container>
          <h2 className='mainTitle text-center mb-4'>OUR FUTURE PARTNERS & SUPPORTERS</h2>

          <PartnerSlider />
        </Container>
      </div>

      <div className='newsSlider pt-150'>
        <Container>
          <h2 className='mainTitle text-center mb-4'>FUTURE PRESS RELEASE</h2>

          <NewsSlider />
        </Container>
      </div>

      <div className='tokenSec pt-150'>
        <Container>
          <h2 className='mainTitle text-center mb-4'>TOKENOMICS</h2>

          <div className='contractAddress mb-5'>
            Contract Address : <span>0X1C07B56D1765D15AA9D28C5E6CFE2CC2A765B27B</span>
          </div>

          <Row className='align-items-center justify-content-center'>
            <Col xs={10} sm={8} md={7} lg={6} xxl={5}>
              <div className='tokenomicsImg text-center'>
                <img src={tokenomicsImg} alt='tokenomicsImg' />
              </div>
            </Col>
            <Col lg={6} xxl={6} className='py-4'>
              <Row className='g-4'>
                <Col sm={6} className='tokenDiv'>
                  <div>
                    <p>Token Name</p>
                    <h3 className='mainTitle2 mb-0'>Oxodise Coin</h3>
                  </div>
                </Col>
                <Col sm={6} className='tokenDiv'>
                  <div>
                    <p>Token Symbol</p>
                    <h3 className='mainTitle2 mb-0'>Oxodise</h3>
                  </div>
                </Col>
                <Col sm={6} className='tokenDiv'>
                  <div>
                    <p>Decimal</p>
                    <h3 className='mainTitle2 mb-0'>18</h3>
                  </div>
                </Col>
                <Col sm={6} className='tokenDiv'>
                  <div>
                    <p>Total Supply</p>
                    <h3 className='mainTitle2 mb-0'>700 Million</h3>
                  </div>
                </Col>
              </Row>

              <div className='contractSlide mt-4'>
                <ul>
                  <li>10% ICO Presale Platform</li>
                  <li>15% Initial Dex offering</li>
                  <li>15% Initial Exchange offering</li>
                  <li>25% Centralized Exc</li>
                  <li>10% ICO Presale Platform</li>
                  <li>15% Initial Dex offering</li>
                  <li>15% Initial Exchange offering</li>
                  <li>25% Centralized Exc</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='roadmapSec pt-150'>
        <Container>
          <h2 className='mainTitle text-center'>OUR ROADMAP</h2>

          <div>
            <RoadmapSlider />
          </div>
        </Container>
      </div>

      <div className='documentSec pt-150'>
        <Container>
          <h2 className='mainTitle text-center mb-4'>DOCUMENTS</h2>

          <div className='d-flex flex-wrap justify-content-center gap-3'>
            <Link to={ROUTES.HOME} className='themeBtn'><IoDocumentTextOutline /> Whitepaper</Link>
            <Link to={ROUTES.HOME} className='themeBtn'><IoDocumentTextOutline /> Onepager</Link>
          </div>
        </Container>
      </div>

      <div className='faqSec pt-150'>
        <Container>
          <h2 className='mainTitle text-center mb-4'>FREQUENTLY ASKED QUESTIONS</h2>
          <p className='text-center mb-5'>All your Oxodise project-related questions & doubts are answered by our experts right here.</p>

          <Row className='justify-content-center g-4'>
            <Col lg={7} xxl={6}>
              <Accordion className='faqAccordion' defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header> What is Oxodise Coin?</Accordion.Header>
                  <Accordion.Body>Oxodise Coin stands as an advanced cryptocurrency, engineered to transform multiple industries through its inclusive ecosystem. Harnessing the power of blockchain technology, Oxodise Coin endeavors to tackle challenges across diverse sectors such as gaming, news reporting, supply chain management, and beyond. The provided solutions prioritize transparency, security, and decentralization, marking a revolutionary approach to industry dynamics.</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>How is Oxodise Coin different from other cryptocurrencies?</Accordion.Header>
                  <Accordion.Body>Oxodise Coin stands as an advanced cryptocurrency, engineered to transform multiple industries through its inclusive ecosystem. Harnessing the power of blockchain technology, Oxodise Coin endeavors to tackle challenges across diverse sectors such as gaming, news reporting, supply chain management, and beyond. The provided solutions prioritize transparency, security, and decentralization, marking a revolutionary approach to industry dynamics.</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>How Can I Purchase Oxodise Coin?</Accordion.Header>
                  <Accordion.Body>Oxodise Coin stands as an advanced cryptocurrency, engineered to transform multiple industries through its inclusive ecosystem. Harnessing the power of blockchain technology, Oxodise Coin endeavors to tackle challenges across diverse sectors such as gaming, news reporting, supply chain management, and beyond. The provided solutions prioritize transparency, security, and decentralization, marking a revolutionary approach to industry dynamics.</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header> What measures does Oxodise Coin take to ensure security and privacy?</Accordion.Header>
                  <Accordion.Body>Oxodise Coin stands as an advanced cryptocurrency, engineered to transform multiple industries through its inclusive ecosystem. Harnessing the power of blockchain technology, Oxodise Coin endeavors to tackle challenges across diverse sectors such as gaming, news reporting, supply chain management, and beyond. The provided solutions prioritize transparency, security, and decentralization, marking a revolutionary approach to industry dynamics.</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header> What is the utility of the Oxodise Coin within its ecosystem?</Accordion.Header>
                  <Accordion.Body>Oxodise Coin stands as an advanced cryptocurrency, engineered to transform multiple industries through its inclusive ecosystem. Harnessing the power of blockchain technology, Oxodise Coin endeavors to tackle challenges across diverse sectors such as gaming, news reporting, supply chain management, and beyond. The provided solutions prioritize transparency, security, and decentralization, marking a revolutionary approach to industry dynamics.</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header> I' m new to cryptocurrencies. Where can I learn more about Oxodise Coin and its projects?</Accordion.Header>
                  <Accordion.Body>Oxodise Coin stands as an advanced cryptocurrency, engineered to transform multiple industries through its inclusive ecosystem. Harnessing the power of blockchain technology, Oxodise Coin endeavors to tackle challenges across diverse sectors such as gaming, news reporting, supply chain management, and beyond. The provided solutions prioritize transparency, security, and decentralization, marking a revolutionary approach to industry dynamics.</Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col xs={10} sm={9} md={8} lg={5} xxl={5} className='order-first order-lg-last'>
              <div className='faqImg text-center'>
                <img src={faqImg} alt='faqImg' />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='contactSec pt-150'>
        <Container className='px-sm-5 pt-lg-5 pb-5'>
          <Row className='justify-content-center align-items-center g-4'>
            <Col xs={10} sm={9} md={8} lg={6}>
              <div className='contactImg text-center'>
                <img src={contactImg} alt='contactImg' />
              </div>
            </Col>
            <Col lg={6}>
              <Form className='contactForm text-center'>
                <h2 className='mainTitle2 mb-4'>NEED ANY HELP!</h2>
                <p className='mb-4'>Require assistance or have inquiries about Oxodise? Simply complete the form, and we'll get back to you promptly.</p>

                <Form.Group className="mb-3" controlId="name">
                  <Form.Control type="text" placeholder="Enter Name" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Control type="email" placeholder="Enter email" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="phone">
                  <Form.Control type="number" placeholder="Phone" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="message">
                  <Form.Control as="textarea" rows={4} placeholder="Message" />
                </Form.Group>

                <Link to={ROUTES.HOME} className='themeBtn mx-auto mt-4'>Submit Now</Link>

              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Home