import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import newsImg1 from '../../Assets/Images/newsImg1.png';

const NewsDetails = () => {

  return (
    <>
      <div className='newDetailSec mt-xxl-5 pt-5'>
        <Container>
          <Row>
            <Col xl={11} xxl={10} className='mx-auto'>
              <h2 className='mainTitle text-center mb-4 mb-lg-5'>OXODISE NEWS</h2>

              <h2 className='mainTitle2'>List Of Cryptocurrency Exchanges By Rating & Popularity</h2>

              <img src={newsImg1} alt='news' className='w-100 my-4' style={{ borderRadius: '20px' }} />

              <div className='mb-5'>
                <p>Cryptocurrency exchanges are websites that allow people to buy, sell, and trade cryptocurrencies, i.e. digital tokens on the blockchain.</p>
                <p>Trading cryptocurrencies means buying or selling crypto tokens just like stocks either for long-term investment or to make quick profits. Either way, a crypto exchange is a place where you can buy and trade digital currencies. Examples of crypto exchanges include Binance, WazirX, Coinbase, etc.</p>
              </div>

              <div className='mb-5'>
                <h3 className='gradientClr mb-3'>Which Is The Best Cryptocurrency Exchanges?</h3>

                <p>There are numerous crypto exchanges or crypto trading platforms out there, which makes it difficult to choose the best one.</p>

                <p>Not all exchanges are allowed or authorized to provide services in all locations. In other words, first, you'd need to filter out exchanges based on your location or geographical area, which can help trim the list.</p>

                <p>Once you have a list of all the crypto exchanges legalized to provide services in your region, research each one based on the following factors and your specific criteria:</p>

                <ul className='p-0 m-0' style={{ listStyle: 'none' }}>
                  <li>Security</li>
                  <li>Reliability</li>
                  <li>Reputation and Trustworthiness</li>
                  <li>Fees and Charges</li>
                  <li>User Interface and User Experience</li>
                  <li>Customer Support</li>
                  <li>90 out of 100 traders prefer crypto exchanges with higher reliability and security rather than low trading fees. Customer support is another crucial factor in determining a good exchange for cryptocurrencies.</li>
                </ul>
              </div>

              <div className='mb-5'>
                <h3 className='gradientClr mb-3'>List Of Cryptocurrency Exchanges</h3>
                <p>There are thousands of crypto exchanges out there. According to blockspot.io, there were 1,668 listed crypto trading platforms at the time of writing. CoinMarketCap, the biggest coin-tracking website in the world, had 245 spot exchanges listed at the time.</p>
                <p>In this article, you'll learn about the top cryptocurrency exchanges in terms of popularity, number of users, liquidity, number of tokens, and trustability.</p>
                <p>Let's start.</p>

                <div className='my-4'>
                  <h3 className='gradientClr mb-3'>1. Binance</h3>
                  <p>Binance is the world's #1 and largest crypto exchange in terms of the number of users and trading volume. As of August 2022, the exchange had over 90 million customers worldwide (source: coinmarketcap.com).</p>
                  <p>It allows users to buy, sell and store their digital assets, including more than 350 cryptocurrencies.</p>
                </div>

                <div className='my-4'>
                  <h3 className='gradientClr mb-3'>2. Coinbase</h3>
                  <p>Coinbase is a US-based crypto trading platform and exchange that aims to provide users with the easiest way to buy, sell, trade and store cryptocurrencies.</p>
                  <p>The exchange's top features include a beginner-friendly UI, a built-in digital wallet, and multiple deposit and withdrawal options.</p>
                </div>

                <div className='my-4'>
                  <h3 className='gradientClr mb-3'>3. Oxodise Change</h3>
                  <p><span className='gradientClr fw-medium'>Oxodise Change</span> is a crypto exchange built as a part of the <span className='gradientClr fw-medium'>Oxodise Ecosystem</span>. It's particularly recognized for its innovative crypto trading mechanism which involves the use of the Automated Market Making (AMM) protocol to enable peer-to-peer token trading between participants.</p>
                  <p className='gradientClr fw-bold'>Oxodise Network Chain: A Look Into The Innovative Blockchain Platform</p>
                </div>

                <div className='my-4'>
                  <h3 className='gradientClr mb-3'>4. Bybit</h3>
                  <p>Bybit is a centralized cryptocurrency exchange (CEX) that features a trade matching engine, enabling fast and secure matching of trade orders.</p>
                  <p>The exchange is also known for its high-quality customer service, including community support, and provides access to over 100 digital assets.</p>
                </div>

                <div className='my-4'>
                  <h3 className='gradientClr mb-3'>5. OKX</h3>
                  <p>OKX is a centralized exchange and crypto wallet platform with millions of users worldwide. It has all the basic trading options, including spot trading and regular crypto trading.</p>
                  <p>Other than that, the platform has additional features like trading bots, block trading, and earning options such as OKX savings, stablecoins, staking, and more.</p>
                </div>

                <div className='my-4'>
                  <h3 className='gradientClr mb-3'>6. Upbit</h3>
                  <p>Upbit is a South Korea-based cryptocurrency exchange that has over 180 crypto coins and digital assets with support for over 300 trading pairs.</p>
                  <p>It's a local exchange providing services to crypto traders and investors in South Korea. Besides the Exchange, It also has an NFT Marketplace and Staking platform.</p>
                </div>

                <div className='my-4'>
                  <h3 className='gradientClr mb-3'>7. KuCoin</h3>
                  <p>KuCoin is a cryptocurrency exchange created with the aim to make crypto trading seamless for everyone in the world. It features an intuitive design with a straightforward registration process.</p>
                  <p>Additionally, KuCoin's high level of security and advanced options like futures trading and P2P trading make it immensely popular.</p>
                </div>

                <div className='my-4'>
                  <h3 className='gradientClr mb-3'>8. Kraken</h3>
                  <p>Kraken is one of the oldest cryptocurrency exchanges and is known for its comparatively relatively low commissions.</p>
                  <p>Additionally, it allows users to earn rewards by staking their tokens. With support for over 100 crypto assets and 7 fiat currencies, it is a popular choice for newbies.</p>
                  <p>Some other popular cryptocurrency exchanges include Bithumb, Gemini, MEXC, Bitfinex, and Gate.io.</p>
                </div>

              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default NewsDetails