import facebook from '../Assets/Images/icons/facebook.png';
import instagram from '../Assets/Images/icons/instagram.png';
import linkedin from '../Assets/Images/icons/linkedin.png';
import twitter from '../Assets/Images/icons/twitter.png';
import telegram from '../Assets/Images/icons/telegram.png';
import youtube from '../Assets/Images/icons/youtube.png';
import discord from '../Assets/Images/icons/discord.png';
import medium from '../Assets/Images/icons/medium.png';
import coinmarket from '../Assets/Images/icons/coinmarket.png';
import ROUTES from './Routes';

export const socialMedia = [
  {
    id: 1,
    icon: facebook,
    link: '#',
  },
  {
    id: 2,
    icon: instagram,
    link: '#',
  },
  {
    id: 3,
    icon: linkedin,
    link: '#',
  },
  {
    id: 4,
    icon: twitter,
    link: '#',
  },
  {
    id: 5,
    icon: telegram,
    link: '#',
  },
  {
    id: 6,
    icon: youtube,
    link: '#',
  },
  {
    id: 7,
    icon: discord,
    link: '#',
  },
  {
    id: 8,
    icon: medium,
    link: '#',
  },
  {
    id: 9,
    icon: coinmarket,
    link: '#',
  },
]

export const useCaseList = [
  {
    id: 1,
    title: 'OXODISE CHANGE',
    description: 'Oxodise Change stands as a decentralized exchange utilizing the Automated Market Making (AMM) protocol, enabling direct peer-to-peer token swaps. By cutting out...',
    btnText: 'Launched',
    link: ROUTES.HOME,
  },
  {
    id: 2,
    title: 'OXODISE VERSE',
    description: 'Oxodise Verse stands as a blockchain-powered gaming ecosystem delivering an immersive 3D gaming experience. Leveraging Web3 technology, it empowers ...',
    btnText: 'Coming Soon',
    link: ROUTES.HOME,
  },
  {
    id: 3,
    title: 'OXODISE PAY',
    description: 'Oxodise Pay, tailor-made for the gaming industry, serves as a decentralized payment solution that simplifies in-game transactions. Harnessing the power of blockchain...',
    btnText: 'Coming Soon',
    link: ROUTES.HOME,
  },
  {
    id: 4,
    title: 'OXODISE VAULT',
    description: 'Oxodise Vault is a user-friendly, secure decentralized wallet crafted to seamlessly manage digital assets. With the integration of top-tier security features and a...',
    btnText: 'Coming Soon',
    link: ROUTES.HOME,
  },
  {
    id: 5,
    title: 'OXODISE PRESS',
    description: 'Oxodise Press emerges as a blockchain-powered decentralized news portal with a focus on delivering reliable and up-to-date information about the dynamic ...',
    btnText: 'Coming Soon',
    link: ROUTES.HOME,
  },
  {
    id: 6,
    title: 'OXODISE CONNECT',
    description: 'Oxodise Connect serves as our centralized exchange platform, acting as a bridge between traditional financial systems and the decentralized realm of blockchain...',
    btnText: 'Coming Soon',
    link: ROUTES.HOME,
  },
  {
    id: 7,
    title: 'OXODISE EXPLORE',
    description: 'Oxodise Explorer introduces a decentralized world map, providing a visual representation of blockchain activities and initiatives worldwide. This innovative solution...',
    btnText: 'Coming Soon',
    link: ROUTES.HOME,
  },
  {
    id: 8,
    title: 'OXODISE CHAIN',
    description: 'Oxodise Chain revolutionizes supply chain management by incorporating blockchain technology, elevating transparency and traceability. Through real-time...',
    btnText: 'In Development',
    link: ROUTES.HOME,
  },
  // {
  //   id: 9,
  //   title: 'OXODISE Network Chain (BNC)',
  //   description: 'The Oxodise Network is built on the Ethereum blockchain to ensure seamless compatibility with EVM features such as high security, low cost, and smart contracts.',
  //   btnText: 'Launched',
  //   link: ROUTES.HOME,
  // },
]