import React, { useEffect, useState } from 'react';
// import Web3 from 'web3';
import { Button, Container } from 'react-bootstrap';
import moneyBag from '../../Assets/Images/icons/moneyBag.png';
import barChart from '../../Assets/Images/icons/barChart.png';
import flame from '../../Assets/Images/icons/flame.png';
import setting from '../../Assets/Images/icons/setting.png';
import history from '../../Assets/Images/icons/history.png';
import refresh from '../../Assets/Images/icons/refresh.png';
import bnb from '../../Assets/Images/icons/bnb.png';
import pancake from '../../Assets/Images/icons/pancake.png';
import { AiOutlineSwap } from 'react-icons/ai';
import { IoChevronDownSharp } from 'react-icons/io5';
import { GoPencil } from 'react-icons/go';
import CustomModal from '../../Configs/CustomModal/CustomModal';
import SelectToken from './SelectToken';
import ManageToken from './ManageToken';
import { Link } from 'react-router-dom';
import { FiHelpCircle } from 'react-icons/fi';
import SlippageSetting from './SlippageSetting';
import ExpertMode from './ExpertMode';

const Swap = () => {
  const [tokenModal, setTokenModal] = useState(false);
  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [selectedToken, setSelectedToken] = useState('');
  const [manageTokenModal, setManageTokenModal] = useState(false);
  const [slippageModal, setSlippageModal] = useState(false);
  const [expertModal, setExpertModal] = useState(false);
  const [swapSide, setSwapSide] = useState('');

  const [account, setAccount] = useState('');
  // const [balance, setBalance] = useState(0);
  // const web3 = new Web3(Web3.givenProvider);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', (accounts) => {
        setAccount(accounts[0] || '');
        // fetchBalance(accounts[0]);
      });
    }
  }, []);

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        // Request account access
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setAccount(accounts[0]);
        // fetchBalance(accounts[0]);
      } catch (error) {
        console.error('User rejected the request');
      }
    } else {
      alert('Please install MetaMask!');
    }
  };

  // const fetchBalance = async (account) => {
  //   const balanceWei = await web3.eth.getBalance(account);
  //   const balanceEth = web3.utils.fromWei(balanceWei, 'ether');
  //   setBalance(balanceEth);
  // };

  return (
    <>
      <div className='pt-5 mt-3'>
        <Container>
          <div className='swapMainSec'>
            {(swapSide) &&
              <div className='swapLeftDiv'>
                {swapSide === 'chart' ? 'CHART' : swapSide === 'flame' ? 'FLAME' : ''}
              </div>
            }
            <div className='swapMainDiv'>
              <div className='swapTopDiv'>
                <h5>Swap</h5>
                <p>Trade tokens in an instant</p>
                <div className='swapIcons d-flex gap-3 justify-content-end'>
                  <Button className='active'><img src={moneyBag} alt='moneyBag' /></Button>
                  <Button onClick={() => { if (swapSide !== 'chart') { setSwapSide('chart') } else { setSwapSide('') } }}><img src={barChart} alt='barChart' /></Button>
                  <Button onClick={() => { if (swapSide !== 'flame') { setSwapSide('flame') } else { setSwapSide('') } }}><img src={flame} alt='flame' /></Button>
                  <Button onClick={() => { setSlippageModal(true) }}><img src={setting} alt='setting' /></Button>
                  <Button><img src={history} alt='history' /></Button>
                  <Button><img src={refresh} alt='refresh' /></Button>
                </div>
              </div>
              <div className='swapMiddleDiv'>
                <div className='mb-4'>
                  <div className='swapLabel cursor-pointer'>
                    <div className='d-flex align-items-center gap-1' onClick={() => { setTokenModal(true); setSelectedToken('BNB'); }}><img src={bnb} alt='bnb' /> BNB <IoChevronDownSharp /></div>
                    <span className='ms-auto'>Balance: 0</span>
                  </div>
                  <div className='swapInput'>
                    <input type='number' placeholder='0.0' value={input1} onChange={(e) => setInput1(e.target.value)} />
                    <span>{input1 || 0} USD</span>
                  </div>
                </div>

                <Button className='swapBtn my-4'><AiOutlineSwap /></Button>

                <div className='mb-4'>
                  <div className='swapLabel cursor-pointer'>
                    <div className='d-flex align-items-center gap-1' onClick={() => { setTokenModal(true); setSelectedToken('CAKE'); }}><img src={pancake} alt='pancake' /> CAKE <IoChevronDownSharp /></div>
                    <span className='ms-auto'>Balance: 0</span>
                  </div>
                  <div className='swapInput'>
                    <input type='number' placeholder='0.0' value={input2} onChange={(e) => setInput2(e.target.value)} />
                    <span>{input2 || 0} USD</span>
                  </div>
                </div>

                <div className='d-flex justify-content-between' style={{ fontSize: '12px' }}>
                  <p>Price</p>
                  <p>1 CAKE = 0.00782457 BNB</p>
                </div>

                <div className='d-flex justify-content-between mb-3'>
                  <p style={{ fontSize: '12px' }}>Slippage Tolerance<span className='ms-2 cursor-pointer' onClick={() => { setSlippageModal(true) }}><GoPencil /></span></p>
                  <span className='text-black fw-bold'>0.5%</span>
                </div>

                {(account && input1) ?
                  <>
                    <Button className='connectBtn mb-2' disabled>Insufficient BNB balance</Button>
                    <p className='text-center' style={{ fontSize: '12px' }}>Insufficient Funds? <Link to={''} className='text-black'>Buy Crypto here.</Link></p>
                  </>
                  :
                  <Button className='connectBtn' onClick={() => { if (!account) { connectWallet() } }}>{account ? 'Enter an Amount' : 'Connect Wallet'}</Button>
                }
              </div>

              {account && input1 &&
                <div className='swapBottomDiv'>
                  <div className='d-flex justify-content-between'>
                    <p>Minimum received <FiHelpCircle /></p>
                    <p>159352 CAKE</p>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <p>Price Impact <FiHelpCircle /></p>
                    <p className='text-black'>0.62%</p>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <p>Trading Fee <FiHelpCircle /></p>
                    <p>3.072 BNB</p>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <p>Route <FiHelpCircle /></p>
                    <p>4 Separate Routes</p>
                  </div>
                </div>
              }
            </div>
          </div>
        </Container>
      </div>

      <CustomModal display={tokenModal} handleClose={() => setTokenModal(false)} size='md' className='swapModal' centered>
        <SelectToken setTokenModal={setTokenModal} selectedToken={selectedToken} setManageTokenModal={setManageTokenModal} />
      </CustomModal>

      <CustomModal display={manageTokenModal} handleClose={() => setManageTokenModal(false)} size='md' className='swapModal' centered>
        <ManageToken setTokenModal={setTokenModal} setManageTokenModal={setManageTokenModal} />
      </CustomModal>

      <CustomModal display={slippageModal} handleClose={() => setSlippageModal(false)} size='md' className='swapModal' centered>
        <SlippageSetting setSlippageModal={setSlippageModal} setExpertModal={setExpertModal} />
      </CustomModal>

      <CustomModal display={expertModal} handleClose={() => setExpertModal(false)} size='md' className='swapModal' centered>
        <ExpertMode setSlippageModal={setSlippageModal} setExpertModal={setExpertModal} />
      </CustomModal>
    </>
  )
}

export default Swap