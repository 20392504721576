import React from 'react';
import '../../Assets/Css/network.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ROUTES from '../../Configs/Routes';
import arrow from '../../Assets/Images/icons/arrow.png';
import ecoBannerImg from '../../Assets/Images/ecoBannerImg.png';
import NetworkTransData from '../Network/NetworkTransData';
import UsecaseSlider from '../Home/UsecaseSlider';
import { useCaseList } from '../../Configs/constants';

const Ecosystem = () => {
  return (
    <>
      <div className='ecoBannerSec pt-5'>
        <Container>
          <Row className='justify-content-evenly align-items-center gy-5'>
            <Col lg={6} xxl={5} className='text-center text-lg-start'>
              <h2 className='mainTitle'>Oxodise Ecosystem</h2>
              <p className='py-4'>The Oxodise Ecosystem offers a comprehensive solutions driven by the Oxodise Coin. It introduces a diverse range of decentralized solutions and revolves around Oxodise Chain, Oxodise Pay, Oxodise Press, Oxodise Verse, Oxodise Change, Oxodise Connect, Oxodise Explorer and Oxodise Vault</p>
              <Link to={ROUTES.HOME} className='themeBtn mx-auto mx-lg-0'>Explore <img src={arrow} alt='' /></Link>
            </Col>
            <Col xs={10} sm={10} md={8} lg={6} xxl={5} className='text-center'>
              <img src={ecoBannerImg} alt='ecosystem' />
            </Col>
          </Row>
        </Container>
      </div>

      <div className='ecoNetworkSec pt-150'>
        <Container>
          <Row>
            <Col lg={6} className='mx-auto text-center'>
              <h2 className='mainTitle'>Oxodise NETWORK CHAIN</h2>
              <h3 className='mainTitle2 gradientClr mb-3'>L2 BLOCKCHAIN</h3>
              <p className='pb-4'>It boosts transaction speed and reduces costs by adding a scalable, efficient layer atop the primary blockchain.</p>
              <div className='d-flex flex-wrap justify-content-center gap-3'>
                <Link to={ROUTES.HOME} className='themeBtn'>Learn More <img src={arrow} alt='' /></Link>
                <Link to={ROUTES.HOME} className='themeBtn'>Explore <img src={arrow} alt='' /></Link>
              </div>
            </Col>
          </Row>
          <div className='mt-5'>
            <NetworkTransData />
          </div>
        </Container>
      </div>

      <div className='useCaseSec pt-150'>
        <Container>
          <h2 className='mainTitle text-center mb-4'>USE CASES</h2>

          <Row className='justify-content-center gy-5'>
            {useCaseList?.map((item, index) => {
              return (
                <Col md={10} lg={6}>
                  <div className='item useCaseDiv'>
                    <h2 className='mainTitle2 gradientClr mb-3'>{item?.title}</h2>
                    <p className='mb-4'>{item?.description}</p>
                    <Link to={item?.link} className='themeBtn mx-auto'>{item?.btnText} <img src={arrow} alt='' /></Link>
                  </div>
                </Col>
              )
            })}
          </Row>
        </Container>
      </div>

    </>
  )
}

export default Ecosystem