import React, { useState } from 'react';
import '../../Assets/Css/news.css';
import { Container, Row, Col, InputGroup, Form, Button } from 'react-bootstrap';
import NewsSlider from './NewsSlider';
import airdrop from '../../Assets/Images/news/airdrop.png';
import youtube from '../../Assets/Images/news/youtube.png';
import kyc from '../../Assets/Images/news/kyc.png';
import blockSAFU from '../../Assets/Images/news/blockSAFU.png';
import newsImg1 from '../../Assets/Images/newsImg1.png';
import newsImg2 from '../../Assets/Images/newsImg2.png';
import { Link } from 'react-router-dom';
import ROUTES from '../../Configs/Routes';
import Pagination from '../../Components/pagination';

const News = () => {
  const [totalPage, setTotalPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  return (
    <>
      <div className='newsCompanySec mt-xxl-5 pt-5'>
        <Container>
          <h2 className='mainTitle text-center mb-4'>OXODISE NEWS</h2>
          <Row className='justify-content-center g-4'>
            <Col lg={3}>
              <div className='newsCompanyDiv'>
                <div className='newsCompanyimg text-center'>
                  <img src={airdrop} className='img-fluid' alt='ecoBannerImg' />
                </div>
                <div className='newsCompanyTxt p-3'>
                  <p>Participate In The Oxodise Airdrop Contest To Seize The Opportunity To Earn $20 Free!</p>
                  <Link to={ROUTES.HOME} className='gradientClr fw-bold'>Read More <i className="fa-solid fa-angles-right"></i></Link>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div className='newsCompanyDiv'>
                <div className='newsCompanyimg text-center'>
                  <img src={youtube} className='img-fluid' alt='ecoBannerImg' />
                </div>
                <div className='newsCompanyTxt p-3'>
                  <p>Join The Oxodise Coin YouTube Bounty Contest And Win $1000 Worth Of Oxodise Coins Free</p>
                  <Link to={ROUTES.HOME} className='gradientClr fw-bold'>Read More <i className="fa-solid fa-angles-right"></i></Link>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div className='newsCompanyDiv'>
                <div className='newsCompanyimg text-center'>
                  <img src={kyc} className='img-fluid' alt='ecoBannerImg' />
                </div>
                <div className='newsCompanyTxt p-3'>
                  <p>Oxodise Team Successfully Completed KYC Verification Process With BlockSAFU</p>
                  <Link to={ROUTES.HOME} className='gradientClr fw-bold'>Read More <i className="fa-solid fa-angles-right"></i></Link>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div className='newsCompanyDiv'>
                <div className='newsCompanyimg text-center'>
                  <img src={blockSAFU} className='img-fluid' alt='ecoBannerImg' />
                </div>
                <div className='newsCompanyTxt p-3'>
                  <p>Oxodise Coin Project Successfully Audited And Approved By BlockSAFU</p>
                  <Link to={ROUTES.HOME} className='gradientClr fw-bold'>Read More <i className="fa-solid fa-angles-right"></i></Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='newsSlider pt-150'>
        <Container>
          <h2 className='mainTitle text-center mb-4'>OXODISE IN THE NEWS</h2>
          <NewsSlider />
        </Container>
      </div>

      <div className='newsSec pt-150'>
        <Container>
          <h2 className='mainTitle text-center text-lg-start mb-4'>NEWS ON <span className='gradientClr'>OXODISE</span></h2>
          <Row className='mb-4'>
            <Col lg={8}>
              <div className='newsDiv'>
                <img src={newsImg1} alt='newsImg' className='w-100 mb-3' />

                <h3 className='mb-3'>List Of Cryptocurrency Exchanges By Rating & Popularity</h3>
                <p>Cryptocurrency exchanges are websites that allow people to buy, sell, and trade cryptocurrencies, i.e. digital tokens on the blockchain. Trading…
                  <Link to={ROUTES.BLOG_DETAIL} className='gradientClr fw-medium'> Continue reading</Link>
                </p>
              </div>
            </Col>
            <Col lg={4} className='newsSidebar'>
              <h4 className='mb-3'>Search Post</h4>
              <InputGroup className="mb-4">
                <Form.Control
                  placeholder=""
                />
                <InputGroup.Text id="basic-addon2" onClick={() => console.log('clicked!')}>Search</InputGroup.Text>
              </InputGroup>

              <h4 className='mb-3'>Recent Posts</h4>

              <ul className='postList'>
                <li><Link to={ROUTES.HOME}>List of Cryptocurrency Exchanges By Rating & Popularity</Link></li>
                <li><Link to={ROUTES.HOME}>How Blockchain Technology Will Change The Future?</Link></li>
                <li><Link to={ROUTES.HOME}>Decoding Cryptocurrency Jargon: Crypto Tokens vs. Coins - A Comprehensive Guide</Link></li>
                <li><Link to={ROUTES.HOME}>Oxodise Launchpad: What Is It And How Does It Work?</Link></li>
                <li><Link to={ROUTES.HOME}>Everything You Need To Know About Oxodise Network Chain</Link></li>
              </ul>
            </Col>
          </Row>

          <Row className='justify-content-center g-4'>
            {[1, 2, 3, 4].map((_item, id) => {
              return (
                <Col lg={6} key={id}>
                  <div className='newsDiv'>
                    <img src={newsImg2} alt='newsImg' className='w-100 mb-3' />

                    <h3 className='mb-3'>How Blockchain Technology Will Change The Future?</h3>
                    <p>Blockchain technology has the potential to revolutionize the future of our world through its high-tech solutions for problems across many…
                      <Link to={ROUTES.BLOG_DETAIL} className='gradientClr fw-medium'> Continue reading</Link>
                    </p>
                  </div>
                </Col>
              )
            })}
          </Row>

          <div className='mt-5'>
            {totalPage > 1 &&
              <Pagination totalPage={totalPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            }
          </div>
        </Container>
      </div>

    </>
  )
}

export default News