import React, { useContext, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import coingecko from '../../Assets/Images/icons/coingecko.png';
import pancake from '../../Assets/Images/icons/pancake.png';
import { FiHelpCircle } from 'react-icons/fi';
import MainContext from '../../Configs/Context/MainContext';
import { toast } from 'react-toastify';

const ExpertMode = ({ setSlippageModal, setExpertModal }) => {
  const context = useContext(MainContext);

  const expertOn = () => {
    let favDrink = prompt('Please type the word "confirm" to enable expert mode.', '');
    switch (favDrink) {
      case "confirm":
        setExpertModal(false);
        setSlippageModal(true);
        context.setExpertMode(true);
        break;
      default:
        toast.warning('Please type the word "confirm" properly.')
    }
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title><span className='me-2' onClick={() => { setSlippageModal(true); setExpertModal(false); }}><i className="fa-solid fa-arrow-left-long" /></span> Expert Mode</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div className='expertWarning mb-3'>
          <span><i class="fa-solid fa-triangle-exclamation"></i></span>
          <p className='mb-0'>Expert mode turns off the 'Confirm' transaction prompt, and allows high slippage trades that often result in bad rates and lost funds.</p>
        </div>

        <p>Only use this mode if you know what you're doing.</p>

        <div className='my-4'>
          <Form.Check type="checkbox" className="exportCheck" label="Don't show this again" />
        </div>

        <Button className='connectBtn mb-3' onClick={() => expertOn()}>Turn On Expert Mode</Button>
        <Button className='connectBtn cancelBtn mb-3' onClick={() => { setSlippageModal(true); setExpertModal(false); }}>Cancel</Button>

      </Modal.Body>
    </>
  )
}

export default ExpertMode