import { FaCaretLeft, FaCaretRight } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';

const Pagination = ({ totalPage, currentPage, setCurrentPage }) => {

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage + 1);
  };

  return (
    <div>
      <ReactPaginate
        previousLabel={<FaCaretLeft />}
        nextLabel={<FaCaretRight />}
        pageCount={Math.ceil(totalPage)}
        onPageChange={handlePageClick}
        forcePage={currentPage - 1}
        containerClassName='userPagination'
        previousLinkClassName="paginationLink"
        nextLinkClassName="paginationLink"
        disabledClassName="paginationDisabled"
        activeClassName="paginationActive gradientClr"
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        pageLabelBuilder={(page) => `Page ${page}`}
      />
    </div>
  );
};

export default Pagination;
