import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import pinksale from '../../Assets/Images/partners/pinksale.png';
import dexview from '../../Assets/Images/partners/dexview.png';
import blockliz from '../../Assets/Images/partners/blockliz.png';
import coingecko from '../../Assets/Images/partners/coingecko.png';
import latoken from '../../Assets/Images/partners/latoken.png';
import azbit from '../../Assets/Images/partners/azbit.png';
import xtcom from '../../Assets/Images/partners/xtcom.png';
import blockchain from '../../Assets/Images/partners/blocksafu.png';
import binance from '../../Assets/Images/partners/binance.png';
import collably from '../../Assets/Images/partners/collably.png';

const PartnerSlider = () => {

  const partnerList = [pinksale, dexview, blockliz, coingecko, latoken, azbit, xtcom, blockchain, binance, collably];

  const options = {
    rtl: true,
    loop: true,
    margin: 20,
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    smartSpeed: 5000,
    autoplayTimeout: 3000,
    responsive: {
      0: {
        items: 2,
      },
      767: {
        items: 3,
      },
      991: {
        items: 3.5,
      },
      1199: {
        items: 4,
      },
      1599: {
        items: 4.5,
      },
    },
  };

  return (
    <OwlCarousel className='owl-theme' {...options}>
      {partnerList.map((item, index) => {
        return (
          <div className='item partnerSlideDiv' key={index}>
            <img src={item} alt='' />
          </div>
        )
      })}
    </OwlCarousel>
  )
}

export default PartnerSlider