import React from 'react'
import { Outlet } from 'react-router-dom'
import SwapHeader from './SwapHeader'
import '../../Pages/Swap/swap.css';

const SwapLayout = () => {
  return (
    <>
      <div>
        <SwapHeader />
        <div className='swapOutletWrapper position-relative'>
          <Outlet />
        </div>
      </div>
    </>
  )
}

export default SwapLayout