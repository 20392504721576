import React from 'react';
import roadArrow from '../../Assets/Images/icons/roadArrow.png';
import OwlCarousel from 'react-owl-carousel';


const RoadmapSlider = () => {
  const options = {
    // loop: true,
    margin: 20,
    // autoplay: true,
    // autoplaySpeed: 3000,
    dots: false,
    responsive: {
      0: {
        items: 1,
      },
      991: {
        items: 1,
        // center: true,
      },
      1199: {
        items: 2,
        // center: false,
      },
    },
  };

  return (
    <OwlCarousel className='roadmapCarousel owl-theme' {...options}>
      <div className='item roadmapDiv'>
        <h2 className='mainTitle2 d-block text-center mb-3'>Q1 2024</h2>
        <div className='roadArrow text-center'>
          <img src={roadArrow} alt='' />
        </div>
        <div className='roadmapTxt p-3 p-sm-4'>
          <h2 className='mainTitle2 mb-3'>Development Of Oxodise Network Chain, Oxodise, And Beta Testing And Live</h2>
          <ul>
            <li>Launch of Oxodise Network Testnet.</li>
            <li>Oxodise Network Blockchain Mainnet Launched.</li>
            <li>Oxodise Change Beta Testing.</li>
            <li>Oxodise Change Officially Launched.</li>
            <li>Start the Last phase of presale with a defined start and end date.</li>
            <li>Initiate user interface and user experience design.</li>
            <li>Start the IDO sale with a defined start and end date.</li>
            <li>Incorporate preliminary security measures.</li>
            <li>Start the IEO sale with a defined start and end date.</li>
            <li>Begin content partnerships for crypto news.</li>
            <li>Design an interactive user interface.</li>
            <li>List Oxodise Coins on DEX and CEX.</li>
          </ul>
        </div>
      </div>
      <div className='item roadmapDiv'>
        <h2 className='mainTitle2 d-block text-center mb-3'>Q2 2024</h2>
        <div className='roadArrow text-center'>
          <img src={roadArrow} alt='' />
        </div>
        <div className='roadmapTxt p-3 p-sm-4'>
          <h2 className='mainTitle2 mb-3'>Development Of OXODISE PRESS And Beta Testing And Launch, Development Of OXODISE VERSE</h2>
          <ul>
            <li>Oxodise Press (Blockchain Decentralized Crypto News Portal)</li>
            <li>Start Development Of Transparent Reporting Protocols.</li>
            <li>Introduce advanced security protocols.</li>
            <li>Start beta testing for token swapping functionalities.</li>
            <li>Launch for the community and early adopters.</li>
            <li>Oxodise Verse (WEB3 & 3D GAME)</li>
            <li>Initiate the design of the game environment.</li>
            <li>Begin development of Web3 protocols.</li>
            <li>Start integrating blockchain elements for in-game assets.</li>
          </ul>
        </div>
      </div>
    </OwlCarousel>
  )
}

export default RoadmapSlider