import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import coingecko from '../../Assets/Images/icons/coingecko.png';
import pancake from '../../Assets/Images/icons/pancake.png';

const ManageToken = ({ setTokenModal, setManageTokenModal }) => {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title><span className='me-2' onClick={() => { setTokenModal(true); setManageTokenModal(false); }}><i className="fa-solid fa-arrow-left-long" /></span> Manage</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div className='manageBtns d-flex gap-3 mb-3'>
          <Button className='flex-fill active'>Lists</Button>
          <Button className='flex-fill'>Tokens</Button>
        </div>

        <Form.Control type='text' className='tokenSearch mb-3' placeholder='https:// or ipfs://' />

        <div className=''>
          <div className='manageSwitch mb-3'>
            <img src={pancake} alt='pancake' />
            <div>
              <h6 className='mb-0'>Shpent BNB Chain MM</h6>
              <p className='mb-0'>8 Tokens</p>
            </div>
            <div className='ms-auto'>
              <Form.Check type="switch" id="custom-switch" name="switch1" />
            </div>
          </div>
          <div className='manageSwitch mb-3'>
            <img src={pancake} alt='pancake' />
            <div>
              <h6 className='mb-0'>Shpent Extended</h6>
              <p className='mb-0'>395 Tokens</p>
            </div>
            <div className='ms-auto'>
              <Form.Check type="switch" id="custom-switch" name="switch1" />
            </div>
          </div>
          <div className='manageSwitch mb-3'>
            <img src={coingecko} alt='pancake' />
            <div>
              <h6 className='mb-0'>CoinGecko</h6>
              <p className='mb-0'>3412 Tokens</p>
            </div>
            <div className='ms-auto'>
              <Form.Check type="switch" id="custom-switch" name="switch1" />
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  )
}

export default ManageToken