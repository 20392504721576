import React from 'react';
import { Container, Nav } from 'react-bootstrap';
import ROUTES from '../../Configs/Routes';
import { NavLink, useLocation } from 'react-router-dom';
// import WOW from 'wowjs';

const SwapHeader = () => {
  const location = useLocation();

  const navMenu = [
    {
      name: 'Swap',
      path: ROUTES.SWAP,
    },
    {
      name: 'Liquidity',
      path: ROUTES.LIQUIDITY,
    },
    {
      name: 'Farming',
      path: ROUTES.FARMING,
    },
    {
      name: 'Staking',
      path: ROUTES.STALKING,
    },
    {
      name: 'Bridge',
      path: ROUTES.HOME,
    },
  ]

  return (
    <>
      <div className='swapHeaderDiv'>
        <Container>
          <Nav className='justify-content-center gap-lg-4'>
            {navMenu.map((item, id) => {
              return (
                <Nav.Link key={id} to={item.path} as={NavLink} className={location.pathname === item.path ? 'gradientClr fw-bold' : ''}>{item.name}</Nav.Link>
              )
            })}
          </Nav>
        </Container>
      </div>
    </>
  )
}

export default SwapHeader